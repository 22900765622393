<template>
  <div class="filters-sidebar__top">
    <div class="filters-sidebar__top__back" @click="toggleFilterSidebar">
      <nuxt-img src="/icons/back_chevron.svg" alt="back arrow" />
    </div>
    <div class="filters-sidebar__top__main">
      <div class="filters-sidebar__top__main__title">Filtri</div>
      <div
        class="filters-sidebar__top__main__reset"
        @click="handleResetFilters"
      >
        Azzera tutti i filtri
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { useUiState, useCategoryPage } from '~/composables';

export default defineComponent({
  name: 'FiltersSidebarTop',
  components: {},
  props: {
    categoryId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { toggleFilterSidebar } = useUiState();
    const { resetFilters } = useCategoryPage(props.categoryId);

    const handleResetFilters = async () => {
      await resetFilters();
    };

    return {
      toggleFilterSidebar,
      handleResetFilters,
    };
  },
});
</script>

<style lang="scss" scoped>
.filters-sidebar__top {
  height: 3.0625rem;
  display: flex;
  padding: 0;
  padding-top: 0 !important;
  border-bottom: var(--form-element-border);
  &__back {
    width: 3.5rem;
    border-right: var(--form-element-border);
    @include flex-center;
  }
  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    width: 100%;
    &__title,
    &__reset {
      @include text-13x16-black;
      text-decoration: underline;
    }
  }
}
</style>
