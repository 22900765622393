<template>
  <SfModal
    class="wishlist-modal__container"
    v-model="isCreateWishlistModalOpen"
    :overlay="true"
    @close="toggleCreateWishlistModal"
  >
    <div class="wishlist-modal">
      <Divider text="Nuova lista preferiti" />
      <CreateNewWishlistForm />
    </div>
  </SfModal>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { SfModal } from '@storefront-ui/vue';
import { useUiState } from '~/composables';
import { Divider } from '~/components/General/FormElements';
import CreateNewWishlistForm from '~/components/Wishlist/CreateNewWishlistForm.vue';

export default defineComponent({
  name: 'CreateWishlistModal',
  components: {
    SfModal,
    CreateNewWishlistForm,
    Divider,
  },
  props: {},
  setup() {
    const { isCreateWishlistModalOpen, toggleCreateWishlistModal } =
      useUiState();

    return {
      isCreateWishlistModalOpen,
      toggleCreateWishlistModal,
    };
  },
});
</script>

<style lang="scss">
.wishlist-modal {
  &__container {
    .sf-modal__container {
      border: 5px solid var(--c-primary);
      --modal-width: 50vw;
      z-index: 4;
      .sf-bar.sf-modal__bar.smartphone-only {
        display: none;
      }
      .sf-button--pure.sf-modal__close.desktop-only.sf-button {
        display: block !important;
        scale: 2;
      }
    }
  }
  @include flex-column;
  align-items: center;
  justify-content: center;
  &__ctas {
    @include flex-column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem;
  }
  &__already-in {
    @include text-16x20-black;
    text-transform: uppercase;
  }
}
@include to-tablet-max {
  .wishlist-modal {
    &__container {
      .sf-modal__container {
        --modal-width: 100vw;
      }
    }
  }
}
@include from-desktop-min {
  .wishlist-modal {
    &__container {
      .sf-modal__container {
        .sf-button--pure.sf-modal__close.desktop-only.sf-button {
          scale: 1.5;
        }
      }
    }
  }
}
</style>
