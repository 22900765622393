var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.optionsArray)?_c('div',{staticClass:"custom-select__container",style:(_vm.specificWidth ? { width: _vm.specificWidth } : {})},[_c('ValidationProvider',{staticClass:"custom-select__validator",class:_vm.isDisabled && 'custom-select__container__disabled',attrs:{"rules":_vm.isRequired ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"custom-select__title"},[_vm._v(" "+_vm._s(_vm.$gt(_vm.selectTitle)))]),_vm._v(" "),_c('t-rich-select',{key:("custom-select-" + _vm.componentKey),staticClass:"custom-select",class:_vm.blackOutline && 'custom-select__black-outline',style:(_vm.specificWidth ? { width: _vm.specificWidth } : {}),attrs:{"required":_vm.isRequired,"valid":_vm.isValid,"disabled":_vm.isDisabled,"placeholder":_vm.$gt(_vm.placeholder),"value":_vm.selectValue || (_vm.defaultValue && _vm.defaultValue.value),"options":_vm.optionsArray},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var index = ref.index;
var isHighlighted = ref.isHighlighted;
var isSelected = ref.isSelected;
var className = ref.className;
var option = ref.option;
var query = ref.query;
return [_c('div',{key:("custom-select__option-" + (option.value + index)),staticClass:"custom-select__option",class:[
            className,
            isHighlighted && 'custom-select__option--highlighted',
            isSelected && 'custom-select__option--selected' ]},[_c('span',{staticClass:"custom-select__option__label"},[_vm._v(_vm._s(option.text))]),_vm._v(" "),_c('a',{staticClass:"custom-select__option__link",attrs:{"href":"javascript:;"},on:{"click":function (e) { return _vm.handleDeleteWishlist(e, option.value); }}})])]}}],null,true)}),_vm._v(" "),(errors[0])?_c('span',{staticClass:"custom-select__error"},[_vm._v("\n      "+_vm._s(_vm.$t(errors[0]))+"\n    ")]):_vm._e()]}}],null,false,3115902614)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }