<template>
  <div
    class="swiper-slide relative"
    :class="{ 'swiper-slide-login': isAuthenticated }"
  >
    <nuxt-link class="image-link" :to="localePath(getProductSlug(product))">
      <picture class="image w-full block">
        <source
          media="(min-width: 767px)"
          :srcset="
            getProductThumbnailImage(product)
              ? getProductThumbnailImage(product)
              : '/icons/placeholder-list.svg'
          "
        />
        <img
          :src="
            getProductThumbnailImage(product)
              ? getProductThumbnailImage(product)
              : '/icons/placeholder-list.svg'
          "
          :alt="product.name"
          width="300"
          :class="
            getProductThumbnailImage(product) === null
              ? 'sf-image--placeholder'
              : ''
          "
          height="200"
          class="w-full h-auto inset absolute"
        />
      </picture>
    </nuxt-link>
    <picture class="tag-symbol">
      <source
        media="(min-width: 767px)"
        :srcset="
          icon && icon.desktop ? icon.desktop : '/homepage/star-icon.svg'
        "
      />
      <img
        :src="icon && icon.mobile ? icon.mobile : '/homepage/star-icon.svg'"
        alt="icon star"
        width="25"
        :class="
          getProductThumbnailImage(product) === null
            ? 'sf-image--placeholder'
            : ''
        "
        height="25"
      />
    </picture>
    <label>{{ product.name }}</label>
    <client-only>
      <div
        class="product-slider__item__price"
        :class="{ 'margin-top-auto': isAuthenticated }"
      >
        <div v-show="npp" class="product-slider__item__price__npp" />
        <SpecialPrice
          v-if="isAuthenticated && showPrice"
          :regular="getRegularPrice(product)"
          :special="null"
        />
      </div>
      <div
        v-if="stockStatus"
        class="stock-status"
        :class="{ 'margin-top-auto': !isAuthenticated }"
      >
        <div class="stock-status__dot" :class="colorClass(stockStatus)" />
        <div class="stock-status__label">{{ stockStatus }}</div>
      </div>
    </client-only>
    <nuxt-link
      v-if="!isAuthenticated"
      class="arrow-link"
      :to="localePath(getProductSlug(product))"
    >
      <nuxt-img src="/homepage/info-icon.svg" alt="arrow" />
      {{ $t('Request INFO') }}
      <nuxt-img src="/homepage/arrow-icon.svg" alt="arrow" />
    </nuxt-link>
  </div>
</template>

<script>
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import SpecialPrice from '~/components/SpecialPrice.vue';
import { productGetters } from '@gemini-vsf/composables';
import { getDiscountedPrice } from '~/helpers/product/productDiscounts';
import { useUser } from '~/composables';

export default defineComponent({
  name: 'ProductSliderItem',
  components: { SpecialPrice },
  props: {
    product: {
      type: [Object],
    },
    icon: {
      type: [Object],
    },
    stockAvailability: {
      type: [Array, Object],
    },
    npp: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      app: { $cookies },
    } = useContext();
    const isAuthenticated = $cookies.get('vsf-customer');
    const colorClass = (stock) =>
      stock === 'Disponibile in magazzino'
        ? 'stock-status__dot--green'
        : 'stock-status__dot--red';

    const stockStatus = computed(() => {
      if (!props.stockAvailability) return null;

      let status = 'Non disponibile';
      props.product.list_sku_variants.forEach((sku) => {
        const stock = props.stockAvailability?.[sku];
        if (stock > 0) {
          status = 'Disponibile in magazzino';
        }
      });

      return status;
    });

    const { user } = useUser();

    const customerAdditionalInfo = computed(() =>
      JSON.parse(user.value?.additional_info || '{}')
    );

    const isA4 = computed(
      () => customerAdditionalInfo.value?.codiceAgente === 'A     4'
    );

    const isA50 = computed(() =>
      user?.value?.segments?.includes(
        'grn:customer:customer_group::65b3c2ddc1b84cf9cd4e64ac'
      )
    );

    const isBulkyProduct = computed(() =>
      props.product?.variants?.some(
        (variant) => variant?.product?.nontrasferibile === true
      )
    );

    const showPrice = computed(
      () => !(isBulkyProduct?.value && (isA4?.value || isA50?.value))
    );

    const getRegularPrice = (product) => {
      const productRegularPrice =
        product.price_range.minimum_price.regular_price.value;
      const productFinalPrice =
        product.price_range.minimum_price.final_price.value;

      if (isA50.value) {
        return getDiscountedPrice(productRegularPrice);
      }
      return productFinalPrice && productFinalPrice < productRegularPrice
        ? productFinalPrice
        : productRegularPrice;
    };

    return {
      colorClass,
      getProductThumbnailImage: productGetters.getProductThumbnailImage,
      getProductSlug: productGetters.getSlug,
      stockStatus,
      isAuthenticated,
      getRegularPrice,
      showPrice,
    };
  },
});
</script>

<style lang="scss">
.product-slider {
  &__item {
    &__price {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      &__npp:after {
        content: '\2731';
        color: var(--c-green);
      }
    }
  }
  .margin-top-auto {
    margin-top: auto;
  }
}
</style>
