<template>
  <SfPrice
    v-if="regular === special"
    :regular="$fc(regular)"
    class="sf-product-card__price"
  />
  <SfPrice
    v-else
    :regular="$fc(regular)"
    :special="special && $fc(special)"
    class="sf-product-card__price"
  />
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { SfPrice } from '@storefront-ui/vue';

export default defineComponent({
  name: 'SpecialPrice',
  props: {
    regular: {
      type: [String, Number],
      default: null,
    },
    special: {
      type: [String, Number],
      default: null,
    },
  },
  components: {
    SfPrice,
  },
});
</script>

<style lang="scss" scoped></style>
