<template>
  <div class="product-slider" v-if="products && products.length > 0">
    <h2 class="flex">
      <picture class="tag-symbol">
        <source
          media="(min-width: 767px)"
          :srcset="
            icon && icon.desktop
              ? icon.desktop
              : '/homepage/star-round-icon.svg'
          "
        />
        <img
          :src="
            icon && icon.mobile ? icon.mobile : '/homepage/star-round-icon.svg'
          "
          alt="icon star"
          width="48"
          height="48"
        />
      </picture>
      {{ $t(title) }}
    </h2>
    <div class="home-work-section-right relative">
      <div class="swiper-slider-wrap">
        <div
          class="swiper"
          v-swiper="SWIPER_SETTINGS"
          :instance-name="sliderId"
        >
          <div class="swiper-wrapper">
            <ProductSliderItem
              v-for="(product, index) in products.filter(Boolean)"
              :key="`${title}-${index}`"
              class="swiper-slide relative"
              :class="{ 'swiper-slide-login': isAuthenticated }"
              :product="product"
              :icon="icon"
              :stock-availability="stockAvailability"
              :npp="(npp && npp[product.merchant_sku]) || false"
            />
          </div>
        </div>
      </div>
      <div class="s-arrow">
        <div
          :id="sliderId + '-button-prev'"
          class="swiper-button-prev button-prev"
        >
          <nuxt-img src="/homepage/left-arrow.svg" alt="arrow" />
        </div>
        <div
          :id="sliderId + '-button-next'"
          class="swiper-button-next button-next"
        >
          <nuxt-img src="/homepage/right-arrow.svg" alt="arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Navigation } from 'swiper';
import { directive } from 'vue-awesome-swiper';
import 'swiper/swiper.scss';
import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import { useGeminiApi, useUser } from '~/composables';
import ProductSliderItem from '~/components/ProductSlider/ProductSliderItem.vue';

SwiperCore.use([Navigation]);

export default defineComponent({
  name: 'ProductSlider',
  components: {
    ProductSliderItem,
  },
  props: {
    products: {
      type: [Array, Object],
    },
    sliderId: {
      type: String,
      required: true,
      default: 'home-default',
    },
    title: {
      type: String,
      default: 'Related products',
    },
    icon: {
      type: [Object],
    },
  },
  setup(props) {
    const hide = (arrowElementId) => {
      document.querySelector(arrowElementId).style.display = 'none';
    };

    const show = (arrowElementId) => {
      document.querySelector(arrowElementId).style.display = 'unset';
    };

    const SWIPER_SETTINGS = {
      loop: false,
      observer: true,
      observeParents: true,
      resizeObserver: true,
      watchOverflow: true,
      on: {
        reachEnd() {
          hide(`#${props.sliderId}-button-next`);
        },
        reachBeginning() {
          hide(`#${props.sliderId}-button-prev`);
        },
        init() {
          hide(`#${props.sliderId}-button-prev`);
        },
        slideChange() {
          if (!this.isBeginning) show(`#${props.sliderId}-button-prev`);
          if (!this.isEnd) show(`#${props.sliderId}-button-next`);
        },
      },
      navigation: {
        nextEl: `#${props.sliderId}-button-next`,
        prevEl: `#${props.sliderId}-button-prev`,
      },
      breakpoints: {
        0: {
          slidesPerView: 2,
          spaceBetween: 16,
          allowTouchMove: true,
        },
        767: {
          slidesPerView: 3,
          spaceBetween: 24,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 24,
        },
        1900: {
          slidesPerView: 6,
          spaceBetween: 24,
        },
      },
    };
    const { isAuthenticated, user, load: loadUser } = useUser();
    const { getStockData, getNppBySkus } = useGeminiApi();
    const stockAvailability = ref(null);
    const npp = ref();

    const aggregateStock = (stockData) => {
      if (!stockData) return null;
      const aggregatedStock = {};

      Object.keys(stockData).forEach((sku) => {
        const stockInfo = stockData[sku].stock;
        aggregatedStock[sku] = Object.values(stockInfo).reduce(
          (a, b) => Number(a) + Number(b),
          0
        );
      });

      return aggregatedStock;
    };

    const fetchProductsStock = async () => {
      if (!props.products) return;
      try {
        const variantSkus = props.products.reduce((acc, product) => {
          if (product.list_sku_variants) {
            return [...acc, ...product.list_sku_variants];
          }
          return acc;
        }, []);

        const stockData = await getStockData(variantSkus);
        stockAvailability.value = aggregateStock(stockData);
      } catch (error) {
        console.error('fetchProductsStock ~ error:', error);
      }
    };

    const fetchNppBySkus = async () => {
      if (!props.products) return;
      if (!user.value) {
        await loadUser();
      }
      try {
        const variantSkus = props.products.reduce((acc, product) => {
          if (product.list_sku_variants) {
            return [...acc, ...product.list_sku_variants];
          }
          return acc;
        }, []);
        const nppData = await getNppBySkus(variantSkus, user.value);
        npp.value =
          nppData &&
          props.products.reduce((acc, product) => {
            const hasNpp = Object.entries(nppData).find(([variantSku]) =>
              product.list_sku_variants.includes(variantSku)
            )?.[1]?.npp;
            return { ...acc, [product.merchant_sku]: hasNpp };
          }, {});
      } catch (error) {
        console.error('fetchNppBySkus ~ ProductSlider ~ error:', error);
      }
    };

    onMounted(async () => {
      await Promise.all([fetchProductsStock(), fetchNppBySkus()]);
    });

    return {
      isAuthenticated,
      SWIPER_SETTINGS,
      stockAvailability,
      npp,
    };
  },
  directives: {
    swiper: directive,
  },
});
</script>

<style lang="scss">
.product-slider {
  h2 {
    @include font-italic;
    margin-bottom: 2.5rem;
    font-weight: normal;
    align-items: var(--align-items);
    @include to-tablet-max {
      font-size: var(--font-size-24);
      line-height: var(--line-height-24);
      margin-bottom: 1.813rem;
    }
    img {
      margin-right: 1.5rem;
      @include to-tablet-max {
        max-width: 1.5rem;
        margin-right: 1rem;
      }
    }
  }
  .s-arrow {
    justify-content: space-between;
    display: var(--hidden);
    @include for-mobile {
      display: var(--hidden);
    }
    .swiper-button-next,
    .swiper-button-prev {
      border: 0;
      background: none;
      padding: 0;
      cursor: pointer;
      position: absolute;
      z-index: 2;
      top: 37%;
      transform: translatey(-50%);
      @include to-tablet-1200 {
        display: var(--hidden);
      }
      &.swiper-button-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
      svg,
      img {
        width: 1.25rem;
        @include for-mobile {
          height: 2.188rem;
          width: auto;
        }
      }
    }
    .button-prev {
      left: 0;
    }
    .button-next {
      right: 0;
    }
  }
  &:hover {
    .s-arrow {
      display: var(--block);
    }
  }
  .swiper-slide {
    position: var(--relative);
    display: var(--flex);
    flex-direction: column;
    height: auto;
    .tag-symbol {
      position: var(--absolute);
      right: 0.063rem;
      top: 0.063rem;
      background: var(--c-yellow);
      width: 3rem;
      height: 3rem;
      display: var(--flex);
      justify-content: center;
      align-items: center;
      @include to-mobile-max {
        width: 1.5rem;
        height: 1.5rem;
      }
      img {
        max-width: 1.875rem;
        max-height: 1.875rem;
        @include to-mobile-max {
          width: 0.813rem;
          height: 0.813rem;
        }
      }
    }
    a.image-link {
      border: solid 0.063rem var(--c-dark-grey);
      color: var(--c-white);
      display: var(--flex);
      justify-content: flex-end;
      align-items: flex-start;
      flex-direction: var(--flex-col);
      background: var(--c-white);
      margin-bottom: 1.625rem;
      @include to-mobile-max {
        margin-bottom: 0.5rem;
      }
      .image {
        line-height: 0;
        position: var(--relative);
        padding-bottom: 100%;
        overflow: hidden;
        img {
          line-height: 0;
          transition: transform 0.5s;
          object-fit: contain;
          transition: transform 0.5s;
          width: var(--w-full);
          height: var(--h-full);
          &.sf-image--placeholder {
            max-width: 80%;
            margin: auto;
          }
        }
      }
      &:hover {
        label {
          background: var(--c-dark-grey);
          color: var(--c-white);
        }
        .image {
          img {
            transform: scale(1.02);
          }
        }
      }
    }
    label {
      font-size: var(--font-size-12);
      line-height: var(--line-height-16);
      text-transform: uppercase;
      display: var(--block);
      margin-bottom: 0.5rem;
      @include to-mobile-max {
        font-family: var(--lato-medium);
      }
    }
    .arrow-link {
      color: var(--c-dark-grey);
      padding-top: 1rem;
      @include to-mobile-max {
        font-size: var(--font-size-13);
        line-height: var(--line-height-16);
      }
      img:first-child {
        margin: 0 0.5rem 0 0;
        max-width: 1rem;
      }
    }
    .short_description {
      /* autoprefixer: off */
      display: -webkit-box !important;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      box-orient: vertical;
      overflow: hidden;
      /* will not be prefixed */
      p {
        display: inline;
        margin: 0 0.25rem 0 0;
        font-size: var(--font-size-16);
        font-family: var(--lato-medium);
        line-height: var(--line-height-20);
        @include to-mobile-max {
          font-size: var(--font-size-13);
          line-height: var(--line-height-16);
        }
      }
    }
    .stock-status {
      display: var(--flex);
      align-items: var(--align-items);
      font-size: var(--font-size-12);
      line-height: var(--line-height-12);
      font-family: var(--lato-medium);
      padding-top: 0.5rem;
      @include to-mobile-max {
        font-family: var(--lato-regular);
        font-weight: normal;
        font-size: var(--font-size-13);
        line-height: var(--line-height-16);
      }
      &__dot {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        margin-right: 0.625rem;
        &--green {
          background-color: var(--c-green);
        }
        &--yellow {
          background-color: var(--c-yellow);
        }
        &--red {
          background-color: var(--c-red);
        }
      }
    }
    .sf-price {
      padding-top: 1rem;
      justify-content: start;
      align-items: center;
      .sf-price__special {
        order: -1;
      }
      .sf-price__regular,
      .sf-price__special {
        font-family: var(--lato-medium);
        font-size: var(--font-size-16);
      }
      .sf-price__old {
        color: var(--c-dark-grey);
        margin-left: 0.5rem;
        color: #9fa6ad;
        font-family: var(--lato-regular);
        font-size: var(--font-size-16);
      }
    }
    &.swiper-slide-login {
      .arrow-link {
        margin-top: 0;
      }
      .sf-price {
        margin-top: auto;
      }
    }
  }
  .swiper:not(.swiper-container-initialized) {
    .swiper-wrapper {
      display: var(--flex);
      flex-wrap: nowrap;
      overflow: hidden;
      .swiper-slide {
        flex: 0 0 15.5%;
        width: var(--w-full);
        margin-right: 1.5rem;
        @include to-tablet-1200 {
          margin-right: 1.5rem;
          flex: 0 0 15.5%;
        }
        @include to-mobile-max {
          margin-right: 1rem;
          flex: 0 0 50%;
        }
      }
    }
  }
}
</style>
