<template>
  <SfModal
    class="wishlist-modal__container"
    v-model="isWishlistModalOpen"
    :overlay="true"
    @close="toggleWishlistModal"
  >
    <div class="wishlist-modal">
      <CustomSelect
        class="wishlist-modal__selector"
        select-name="wishlist-modal-selector"
        select-title="A quale wishlist desideri aggiungere il prodotto?"
        black-outline
        specific-width="100%"
        :select-options="selectableUserWishlists"
        @selectChange="(w) => handleWishlistChange(w)"
        :selected-value="(wishlist && wishlist.uid) || null"
        v-if="selectableUserWishlists.length > 0 && !createNewWishlist"
      />
      <div
        v-if="isInWishlist && !createNewWishlist"
        class="wishlist-modal__already-in"
      >
        Il prodotto è già presente nella lista preferiti selezionata
      </div>
      <Divider v-if="createNewWishlist" text="Nuova wishlist" />
      <CreateNewWishlistForm
        v-if="createNewWishlist"
        :create-and-add-to-wishlist="true"
      />
      <div class="wishlist-modal__ctas">
        <CustomButton
          v-show="!createNewWishlist"
          :disabled="!!isInWishlist || !currentWishlistUid"
          class="wishlist-modal__button"
          @click="handleClick"
          specific-width="fit-content"
        >
          AGGIUNGI AI PREFERITI
        </CustomButton>
        <CustomButton
          class="wishlist-modal__button"
          @click="createNewWishlist = !createNewWishlist"
          specific-width="fit-content"
          theme="black"
        >
          {{
            createNewWishlist
              ? `ANNULLA LA CREAZIONE`
              : `CREA UNA NUOVA LISTA PREFERITI E AGGIUNGI`
          }}
        </CustomButton>
      </div>
    </div>
  </SfModal>
</template>

<script>
import {
  defineComponent,
  shallowRef,
  onMounted,
  computed,
} from '@nuxtjs/composition-api';
import { SfModal } from '@storefront-ui/vue';
import { useUiState, useUserWishlists } from '~/composables';
import {
  CustomSelect,
  CustomButton,
  Divider,
} from '~/components/General/FormElements';
import CreateNewWishlistForm from '~/components/Wishlist/CreateNewWishlistForm.vue';

export default defineComponent({
  name: 'WishlistModal',
  components: {
    SfModal,
    CustomSelect,
    CustomButton,
    CreateNewWishlistForm,
    Divider,
  },
  props: {},
  setup() {
    const {
      isWishlistModalOpen,
      toggleWishlistModal,
      wishlistModalData,
      toggleWishlistSidebar,
    } = useUiState();
    const {
      selectableUserWishlists,
      changeWishlist,
      loadItemsFromList,
      loadWishlist,
      wishlist,
      addItemToWishlist,
      currentWishlistUid,
      itemsInWishlist,
    } = useUserWishlists();
    const createNewWishlist = shallowRef(false);

    const checkIfAlreadyInWishlist = async () => {
      await loadItemsFromList([wishlistModalData?.value?.sku]);
    };

    const isInWishlist = computed(() =>
      itemsInWishlist.value &&
      currentWishlistUid.value &&
      itemsInWishlist.value[currentWishlistUid.value]
        ? !!Object.keys(itemsInWishlist.value[currentWishlistUid.value])?.[
            wishlistModalData?.value?.sku
          ]
        : false
    );

    const handleWishlistChange = async (w) => {
      await changeWishlist(w);
      await checkIfAlreadyInWishlist();
    };

    const handleClick = async () => {
      await addItemToWishlist({ product: wishlistModalData.value });
      await loadWishlist();
      toggleWishlistModal();
      toggleWishlistSidebar();
    };

    onMounted(async () => {
      if (!wishlist.value) {
        await loadWishlist();
      }
      await checkIfAlreadyInWishlist();
    });

    return {
      wishlistModalData,
      toggleWishlistModal,
      isWishlistModalOpen,
      selectableUserWishlists,
      handleWishlistChange,
      wishlist,
      handleClick,
      createNewWishlist,
      currentWishlistUid,
      isInWishlist,
      itemsInWishlist,
    };
  },
});
</script>

<style lang="scss">
.wishlist-modal {
  padding: 1rem;
  &__container {
    .sf-modal__container {
      border: 5px solid var(--c-primary);
      --modal-width: 50vw;
      z-index: 4;
      .sf-bar.sf-modal__bar.smartphone-only {
        display: none;
      }
      .sf-button--pure.sf-modal__close.desktop-only.sf-button {
        display: block !important;
        scale: 2;
      }
    }
  }
  @include flex-column;
  align-items: center;
  justify-content: center;
  &__ctas {
    @include flex-column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem;
  }
  &__already-in {
    @include text-16x20-black;
    text-transform: uppercase;
  }
}
@include to-tablet-max {
  .wishlist-modal {
    &__container {
      .sf-modal__container {
        --modal-width: 100vw;
      }
    }
  }
}
</style>
