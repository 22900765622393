var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SfSidebar',{staticClass:"wishlist-sidebar",attrs:{"visible":_vm.isWishlistSidebarOpen,"title":"I tuoi preferiti","position":"right"},on:{"close":_vm.toggleWishlistSidebar},scopedSlots:_vm._u([{key:"circle-icon",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"wishlist-sidebar__top-right",on:{"click":close}},[_c('div',{staticClass:"wishlist-sidebar__top-right__new",on:{"click":_vm.toggleCreateWishlistModal}},[(_vm.isAuthenticated)?_c('PlusIcon',{staticClass:"wishlist-sidebar__top-right__new__icon"}):_vm._e(),_vm._v(" "),(_vm.isAuthenticated)?_c('span',{staticClass:"wishlist-sidebar__top-right__new__label"},[_vm._v("\n          Nuova lista preferiti\n        ")]):_vm._e()],1),_vm._v(" "),_c('Cross',{on:{"click":close}})],1)]}},{key:"content-top",fn:function(){return [(_vm.isAuthenticated)?_c('div',{staticClass:"wishlist-sidebar__top"},[(_vm.selectableUserWishlists.length > 0 && !_vm.loading)?_c('CustomRichSelect',{key:("wishlist-sidebar-selector-" + ((_vm.wishlist && _vm.wishlist.uid) || Date.now())),staticClass:"wishlist-sidebar__top__selector",attrs:{"select-name":"wishlist-sidebar-selector","select-title":"Seleziona una lista preferiti","black-outline":"","specific-width":"100%","select-options":_vm.selectableUserWishlists,"selected-value":(_vm.wishlist && _vm.wishlist.uid) || null,"reload-options":_vm.loadUserWishlists},on:{"selectChange":function (w) { return _vm.handleWishlistChange(w); }}}):_vm._e(),_vm._v(" "),_c('CustomInput',{staticClass:"wishlist-sidebar__top__search",attrs:{"input-name":"wishlist-sidebar-search","input-id":"wishlist-sidebar-search","input-placeholder":"Nome/SKU prodotto...","input-label":"Cerca nei preferiti","override-style":{ width: '100%' }},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1):_vm._e()]},proxy:true}])},[_vm._v(" "),_vm._v(" "),(_vm.loading)?_c('LoadingDots'):_vm._e(),_vm._v(" "),(
      _vm.isAuthenticated &&
      !_vm.loading &&
      _vm.wishlist &&
      _vm.wishlist.items &&
      _vm.wishlist.items.items.length > 0
    )?_c('div',{staticClass:"wishlist-sidebar__items"},_vm._l((_vm.filteredWishlistItems),function(wishlistItem,wishlistItemIndex){return _c('WishlistSidebarItem',{key:("wishlist-sidebar-" + (_vm.wishlist.uid) + "-item-" + wishlistItemIndex + "-" + (wishlistItem.uid)),attrs:{"product":wishlistItem.product,"wishlist-item-uid":wishlistItem.uid,"wishlist-item-grn":wishlistItem.item_grn}})}),1):_vm._e(),_vm._v(" "),(!_vm.isAuthenticated && !_vm.loading)?_c('div',{staticClass:"wishlist-sidebar__empty"},[_vm._v("\n    Effettua l'accesso per visualizzare i tuoi prodotti preferiti\n    "),_c('CustomButton',{on:{"click":_vm.toggleLoginAndRegisterModal}},[_vm._v(" Accedi ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }