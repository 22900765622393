<template>
  <div
    v-if="product && product.name"
    class="sidebar__item"
    :class="loading && 'sidebar__item__disabled'"
  >
    <nuxt-link
      :to="localePath(productUrl)"
      class="sidebar__item__image__container"
    >
      <nuxt-img
        class="sidebar__item__image"
        :src="thumbnailUrl"
        alt="product image"
      />
    </nuxt-link>
    <div class="sidebar__item__name">
      <!-- <span class="sidebar__item__name__label">NOME</span> -->
      <span class="sidebar__item__name__value">{{ product.name }}</span>
    </div>
    <div class="sidebar__item__sku">
      <span class="sidebar__item__sku__value"> {{ variantSku }}</span>
    </div>
    <div class="sidebar__item__options" v-show="simpleProductData">
      <div
        class="sidebar__item__options__option"
        v-for="(option, optionIndex) in simpleProductData"
        :key="`wishlist-item-${product.uid}-${optionIndex}`"
      >
        <span class="sidebar__item__options__option__label">
          {{ option.label }}
        </span>
        <span class="sidebar__item__options__option__value">
          {{ option.value }}
        </span>
      </div>
    </div>
    <div class="sidebar__item__price" v-if="showPrice">
      <span class="sidebar__item__price__special">
        {{ $fc(productPrice.minimum_price.final_price.value) }}
      </span>
    </div>
    <div class="sidebar__item__remove" @click="handleRemove">
      <Cross />
    </div>
    <div class="sidebar__item__add-to-cart" @click="handleAddToCart">
      <CartIcon />
    </div>
  </div>
  <div v-else />
</template>

<script>
import {
  defineComponent,
  computed,
  shallowRef,
  useContext,
  useRouter,
} from '@nuxtjs/composition-api';
import { Cross, CartIcon } from '~/components/General/Icons';
import { useUserWishlists, useCart, useUser } from '~/composables';
import { productGetters } from '@gemini-vsf/composables';

export default defineComponent({
  name: 'WishlistSidebarItem',
  components: { Cross, CartIcon },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    wishlistItemUid: {
      type: String,
      required: true,
    },
    wishlistItemGrn: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { removeItemFromWishlist } = useUserWishlists();
    const { getSlug } = productGetters;
    const { addItem } = useCart();
    const { localePath } = useContext();
    const router = useRouter();
    const { user } = useUser();

    const loading = shallowRef(false);

    const productPrice = computed(() =>
      !props?.wishlistItemGrn?.includes('/')
        ? props?.product?.price_range
        : props?.product?.variants?.find(
            (v) => v?.product?.uid === props?.wishlistItemGrn
          )?.product?.price_range
    );

    const thumbnailUrl = computed(() =>
      props.product?.thumbnail?.url
        ? `${props.product?.thumbnail?.url}?w=200`
        : '/icon.png'
    );

    const productUrl = computed(() => getSlug(props.product));

    const simpleProductData = computed(() => {
      if (!props.wishlistItemGrn.includes('/')) return null;
      const variant = props.product.variants.find(
        (v) => v.product.uid === props.wishlistItemGrn
      );
      const selectedOptions = variant.option_uids;
      const optionValues = selectedOptions.map((o) => {
        const attributeUid = o.split(':')[0];
        const option = props.product.configurable_options.find(
          (p) => p.attribute_uid === attributeUid
        );
        const valueLabel = option.values.find((v) => v.uid === o).label;
        const optionLabel = option.label;
        const optionUid = option.values.find((v) => v.uid === o).uid;
        return {
          label: optionLabel,
          value: valueLabel,
          uid: optionUid,
        };
      });
      return optionValues;
    });

    const customerAdditionalInfo = computed(() =>
      JSON.parse(user.value?.additional_info || '{}')
    );

    const isA4 = computed(
      () => customerAdditionalInfo.value?.codiceAgente === 'A     4'
    );

    const isA50 = computed(() =>
      user?.value?.segments?.includes(
        'grn:customer:customer_group::65b3c2ddc1b84cf9cd4e64ac'
      )
    );

    const isBulkyProduct = computed(() =>
      props.product?.variants?.some(
        (variant) => variant?.product?.nontrasferibile === true
      )
    );

    const showPrice = computed(
      () => !(isBulkyProduct?.value && (isA4?.value || isA50?.value))
    );

    const handleRemove = async () => {
      loading.value = true;
      await removeItemFromWishlist(
        { product: props.product },
        props.wishlistItemUid
      );
      loading.value = false;
    };

    const handleAddToCart = async () => {
      const simpleProductSku = props.wishlistItemGrn.includes('/')
        ? props.wishlistItemGrn
        : null;
      if (!simpleProductSku) {
        router.push({
          path: localePath(productUrl.value),
          query: { addToCart: true },
        });
        return;
      }
      loading.value = true;
      const quantity =
        props?.product?.variants?.find(
          (v) => v.product?.uid === simpleProductSku
        )?.venditamultiplidiarticoliweb || 1;
      await addItem(
        {
          item: {
            ...props.product,
            configurable_product_options_selection: {
              variant: { sku: simpleProductSku },
            },
          },
          quantity,
          configurations: simpleProductData.value?.map((o) => o?.uid) || [],
        },
        false // don't open cart sidebar
      );
      loading.value = false;
    };

    const variantSku = computed(() => {
      if (!props.wishlistItemGrn.includes('/')) return null;
      const variant = props.product.variants.find(
        (v) => v.product.uid === props.wishlistItemGrn
      );
      return variant.product.merchant_sku || '';
    });

    return {
      handleRemove,
      handleAddToCart,
      variantSku,
      loading,
      thumbnailUrl,
      productUrl,
      simpleProductData,
      productPrice,
      showPrice,
    };
  },
});
</script>

<style lang="scss" scoped>
.sidebar__item {
  display: grid;
  grid-template-columns: 30% 1fr 20% 20%;
  grid-template-areas:
    'image name name remove'
    'image sku options options'
    'image price price add-to-cart';
  gap: 0.5rem;
  border-bottom: 1px solid var(--c-grey);
  padding: 1rem 0;
  &__image {
    &__container {
      grid-area: image;
      @include flex-center;
    }
    width: 70%;
    height: 70%;
    object-fit: contain;
  }
  &__name {
    grid-area: name;
    word-break: break-word;
    padding-top: 0.2rem;
    @include text-13x16-black;
  }
  &__sku {
    grid-area: sku;
    @include text-13x16-regular;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__price {
    grid-area: price;
    display: flex;
    gap: 0.2rem;
    align-items: center;
    &__regular {
      &__barred {
        text-decoration: line-through;
        color: var(--c-red-error);
      }
    }
    &__regular,
    &__special {
      @include text-13x16-regular;
    }
  }
  &__remove {
    grid-area: remove;
  }
  &__add-to-cart {
    grid-area: add-to-cart;
  }
  &__remove,
  &__add-to-cart {
    @include flex-center;
    cursor: pointer;
    margin: 0.3rem;
    scale: 0.8;
  }
  &__disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &__options {
    grid-area: options;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    &__option {
      display: flex;
      flex-direction: row;
      gap: 0.2rem;
      &__label {
        @include text-13x16-regular;
      }
      &__value {
        @include text-13x16-regular;
        font-weight: 600;
      }
    }
  }
}
@include from-desktop-min {
  .sidebar__item {
    &__price {
      gap: 1rem;
      flex-direction: row;
    }
  }
}
</style>
